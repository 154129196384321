import { graphql } from 'gatsby'
import React from 'react'
import Seo from '../components/Seo'
import { PAGE_VIEWED } from '../constants/metrics'
import DocumentationLayout from '../layouts/DocumentationLayout'
import MetricsService from '../services/metricsService'
import GuideMarkdown from '../components/GuideMarkdown'

interface IFAQProps {
  data: {
    strapiFaq: {
      data: any
    }
  }
}

export const query = graphql`
  query FaqQuery {
    strapiFaq {
      data
    }
  }
`

const FAQ: React.FC<IFAQProps> = ({ data }): JSX.Element => {
  const content = data.strapiFaq.data
  const metricsService = MetricsService.getInstance()
  const pageName = 'FAQ'

  metricsService.track(PAGE_VIEWED, {
    pageName: pageName,
  })

  return (
    <DocumentationLayout>
      <Seo title={pageName} />
      <GuideMarkdown content={content} />
    </DocumentationLayout>
  )
}

export default FAQ
